<template>
  <esmp-input
    disabled
    :label="label"
    :options="{ type: 'textarea' }"
  />
</template>

<script>
export default {
  name: 'TextareaView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    label() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
  },
};
</script>
